/*!

=========================================================
*
=========================================================

* 

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import {getAuth } from "firebase/auth"
import { httpsCallable, getFunctions } from "firebase/functions";
import './FWLinuxServices.css' // Same for AIMLDS if needed make copy and change
// reactstrap ../../components
import {
//  Badge,
  Button,
  Card,
  CardBody,
//  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core ../../components
import DemoNavbar from "../components/Navbars/DemoNavbar.js";
import CardsFooter from "../components/Footers/CardsFooter.js";


// index page sections
//import Download from "../IndexSections/Download.js";

/* Top Co Cfg */
import hostingCfg from "../hostingcfg/hostingcfg.json"
/*  ---- Move to Components later --------- */
/*const REACT_APP_PRJQUERY_BEPY_URL = process.env.REACT_APP_PRJQUERY_BEPY_URL*/
const functions = getFunctions();

class AIMLDS extends React.Component {
  state = {
    name: '',
    email: '',
    message: '',
    nameFocused: false,
    emailFocused: false
  };
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, message } = this.state;
    const auth = getAuth();
    console.log('Auth = ', {auth})
    if(auth && auth.currentUser)
    {
        //console.log('currentUser = ', {auth.currentUser})
        try {
            console.log('Response from BE:', name, email, message);
            //console.log("project url ",REACT_APP_PRJQUERY_BEPY_URL)
    /*      const response = await axios.post('https://your-backend-api-url.com/userData', {
            name,
            email,
            message
          }); */
          //console.log('Response from BE:', response.data);
          // Optionally handle success or navigate to another page
        } catch (error) {
          console.error('Error sending data:', error);
          // Optionally handle error
        }
   /*  ---- Move to Components later --------- */
    // Call Cloud Function to process the file
    const projectQuery = httpsCallable(functions, 'projectQuery', { method: 'POST' });
    const data = { name: name, email: email, message:message };
    
    projectQuery(data)
      .then((result) => {
        console.log('Project Query result=', { result });
        if (result.data.success === true) {
          //setProcessSuccess(true);
        } else {
          alert('Failed to process message');
        }
        // setProcessedFileUrl(result.data.download_link);
      })
      .catch((error) => {
        console.error('Error processing message:', error);
        if (error.error === 'Failed to process Message') {
          alert('Failed to process Message');
        } else {
          alert('An unexpected error occurred.');
        }
      })
      .finally(() => {
        
      });
    /*  ---- Move to Components later --------- */    
    }
    else
        alert('You are not signed in to send Information ');
  };
  
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg bg-gradient-blue section-shaped pb-0" >
              <div className="shape shape-style-1 shape-default " >
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <Container fluid className="py-0 d-flex"> {/* Use fluid to fill the entire width */}
                <Row className="row-grid align-items-center"> {/* Align items as needed */}
                <Col xl="12" lg="12" md="12" sm="12" className="text-left">             
                 <h1 className="display-3 text-white align-items-center">
                   Welcome to Our AI/ML and Data analysis Page
                 </h1>
                 <p className="lead text-white">
                    Our AI/ML and data analysis capabilities transform large volumes of raw data into actionable insights, enhancing your decision making process.
                </p>
                <p className="lead text-white">
                We offer :-
                </p>
                </Col>
                </Row>              
                </Container> {/* Use fluid to fill the entire width */}
                <Container fluid className="py-0 d-flex"> {/* Use fluid to fill the entire width */}
              <Row className="row-grid align-items-center"> {/* Align items as needed */}
                <Col xl="7" lg="7" md="8" sm="12" className="text-left"> {/* Ensure left alignment */}
                
                <p className="lead text-white">
                <div className="lead text-white">                       
  <div className="bullet-point">
    <span className="highlight"> Deep Insights:</span> 
    <span className="smaller-text">
     We uncover hidden patterns and provide deep insights from your raw data, helping you understand trends and make informed decisions.
</span>
  </div>
  <div className="bullet-point">
    <span className="highlight"> Model Training and Development:</span> 
    <span className="smaller-text">
    We specialize in development of custom models, including LLMs, LVMs tailored to specific needs. We also provide solution for classification, defect detection enabling automated Quality checks.
</span>
  </div>
  <div className="bullet-point">
    <span className="highlight">Model Optimization:</span> 
    <span className="smaller-text"> We optimize models to fit your hardware ensuring cost efficiency and seamless deployment.
    </span>
  </div>
</div>
                      </p>
                    </Col>
                <Col className="order-lg-2 text-center" sm="12" md="4" lg="5" xl="5">
                  <div className="card-profile-image">                    
                          <img
                            alt="..."
                            src={require("../assets/img/theme/MgmtDiscuss.png")}
                            className="img-fluid" // Make image responsive
                            style={{ maxHeight: "100%", width: "auto" }}                             
                          /> 
                      </div>
                    </Col>
                  </Row>
              </Container>

            </section>
            {/* 1st Hero Variation */}
          </div>

          <section className="section section-lg pt-lg-0 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--90">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Looking reliable partner for your project, Look no further </h4>
                      <p className="mt-0">Your project is very important to us. Share your requirement/contact (Accepted from Signed-In users only) </p>
                      <form onSubmit={this.handleSubmit}>
                        <FormGroup className={classnames("mt-5", { focused: this.state.nameFocused })}>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-user-run" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Your name"
                              type="text"
                              name="name"
                              value={this.state.name}
                              onFocus={() => this.setState({ nameFocused: true })}
                              onBlur={() => this.setState({ nameFocused: false })}
                              onChange={this.handleChange}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className={classnames({ focused: this.state.emailFocused })}>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Email address"
                              type="email"
                              name="email"
                              value={this.state.email}
                              onFocus={() => this.setState({ emailFocused: true })}
                              onBlur={() => this.setState({ emailFocused: false })}
                              onChange={this.handleChange}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb-4">
                          <Input
                            className="form-control-alternative"
                            cols="180"
                            name="message"
                            placeholder="Type a message..."
                            type="textarea"
                            value={this.state.message}
                            onChange={this.handleChange}
                            rows="4"
                          />
                        </FormGroup>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="submit"
                        >
                          Send Message
                        </Button>
                      </form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default AIMLDS;
